*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}
body {
  box-sizing: border-box;
  padding: 0;
  height: 100vh;
  width: 100vw;
  background-color: #eee;
  overflow: hidden;
  display: block;
}
html {
  font-size: 10px;
}
::selection {
  background-color: #52489c;
  color: #fff;
}
a {
  color: #424b54;
}
select {
  -moz-appearance: none !important;
  background: transparent !important;
  background-image: url("data:image/svg+xml;utf8,<svg fill='' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>") !important;
  -webkit-appearance: none !important;
  background-repeat: no-repeat !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  background-position-x: 95% !important;
  background-position-y: 50% !important;
  border: none;
  color: #424b54;
  cursor: pointer;
}
select:active,
select:focus {
  outline: none;
}
option {
  background-color: #eee;
  cursor: pointer;
}
/* nav */
ul.nav {
  display: flex;
  margin-top: 0.5rem;
  position: fixed;
  margin-left: 0.7rem;
}
.navbar , .zoom{
  padding: 0.2rem 0.7rem;
  margin-left: 5rem;
  display: flex;
  background-color: #eee;
  box-shadow: 0px 0.2rem 0.8rem rgb(1 1 1/ 20%) !important;
  border-radius: 1rem !important;
  border-radius: 2rem !important;
}
.nav-item, .zoom-item {
  margin: 0.2rem 1.5rem;
  list-style: none;
  cursor: pointer;
}
li.nav-item a {
  color: #424b54;
  font-size: 12px;
  font-weight: 500;
}
li.nav-item a.active {
  color: #52489c;
}
.option {
  padding: 0.2rem 0.5rem;
  padding-right: 2rem;
}
/* tree */
/* svg {
  padding-top: 1rem;
} */
.node {
  cursor: pointer;
}
.overlay {
  background-color: #eee !important;
}

.nodeCircle {
  fill: #52489c;
  stroke: #52489c;
  stroke-width: 1.5px;
  cursor: pointer;
}

.node text {
  font-size: 10px;
  font-family: sans-serif;
}

.link {
  fill: none;
  /* stroke: #52489c85; */
  /* stroke: lightsteelblue; */
  stroke-width: 1.2px;
  /* opacity: 0.5; */
} 
.zoom{
  position: absolute;
  bottom: 3rem;
  right:3rem
}
.zoom-item{
  padding: 0 1rem;
  color: #424b54;
  font-weight: 700;
  font-size: 1.5rem;
}
.zoom-item:hover{
  color: #52489c;
  font-size: 1.6rem;
}